<template>
  <div class='base-news-page'>
    <div class="container" v-if="!loading && news">
      <BaseBread>
        <BaseBreadItem to="/">首页</BaseBreadItem>
        <BaseBreadItem>新闻中心</BaseBreadItem>
      </BaseBread>
      <div class="news-info">
        <div class="spec">
          <NewsName :news="news" />
          <div class="media">
              <NewsImage :images="news.image" />
          </div>
          <div class="video-play">
                <BaseVideoPlayer :options="videoOption"></BaseVideoPlayer>
           </div>
          <div>
           <div v-if="news.isPicture == 0" class="content-box" v-html="news.content"></div>
				   <div v-if="news.isPicture == 1" class="content-box" style="width: 100%;">
					  <div  v-for="(item, index) in news.contentImage" :key="index" style="width: 100%;">
              <img  :src="item.filePath" style="width: 100%;"></div>
				  </div>
          </div>
         </div>
        <div class="news-footer">
          <div class="news-aside">
             <NewsHot />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import NewsImage from './news-image'
import NewsName from './news-name'
import NewsHot from './news-hot'
import { nextTick, provide, ref, watch,reactive } from 'vue'
import { findNews } from '@/api/product'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Message from '@/components/library/Message'
export default {
  name: 'BaseNewsPage',
  components: { NewsImage,  NewsName,  NewsHot },
  setup () {
    const { news, loading ,videoOption} = useNews()
    // 提供news数据给后代组件使用
    provide('news', news)
    // 加入购物车
    const store = useStore()
   
     return { news, loading,videoOption}
  }
}
// 获取产品详情
const useNews = () => {
  // 出现路由地址产品ID发生变化，但是不会重新初始化组件
  const news = ref(null)
  const route = useRoute()
  const loading = ref(false)
  const videoOption= ref(null)
  watch(() => route.params.newsId, (newVal) => {
    if (newVal && `/news/detail/${newVal}` === route.path) {
      loading.value = true
      findNews(route.params.newsId).then(data => {
        // 让产品数据为null然后使用v-if的组件可以重新销毁和创建
        news.value = null
        nextTick(() => {
          news.value = data.data.detail
          loading.value = false
        })
        videoOption.value = {
            autoplay: true,
            controls: true,
            sources: [
              {
                src: data.data.detail.videoFilePath,
                type: "video/mp4",
              },
            ],
            width: "870px",
            height: "490px",
         };

      })
    }
  }, { immediate: true })
  return { news, loading ,videoOption}
}
</script>

<style scoped lang='less'>
.loading {
  height: 580px;
  width: 1240px;
  margin: 72px auto 20px;
  background: rgba(255,255,255,.9) url(../../../assets/images/loading.gif) no-repeat center;
}
.news-info {
  min-height: 600px;
  background: #fff;
  display: flex;
  .media {
    width: 580px;
    height: 500px;
    padding: 30px 50px;
  }
  .spec {
    flex: 1;
    padding: 30px 30px 30px 0;
  }
}
.news-footer {
  display: flex;
  margin-top: 20px;
  .news-article {
    width: 940px;
    margin-right: 20px;
  }
  .news-aside {
    width: 280px;
    min-height: 1000px;
  }
}
.video-play {
  margin: 50px;
      border: 10px solid #e4e4e4;
}
.content-box {
	width: 100%;
  padding: 50px;
}

// .news-tabs {
//   min-height: 600px;
//   background: #fff;
// }
// .news-warn {
//   min-height: 600px;
//   background: #fff;
//   margin-top: 20px;
// }
</style>
